import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/frostbyte/frostbyte"

export default function frostbyte() {
  return (
    <Layout>
      <SEO title="FrostBytes" />
      <Content />
    </Layout>
  )
}
