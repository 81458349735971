import React from "react"
import Section from "../global/Section"
import Banner from "../../images/forstbyte/banner.png"
import frostbyte1 from "../../images/forstbyte/frostbyte1.png"
import frostbyte2 from "../../images/forstbyte/frostbyte2.png"
import frostbyte3 from "../../images/forstbyte/frostbyte3.png"
import demo from "../../images/forstbyte/frostbytes.mp4"
export default function frostbyte() {
  return (
    <>
      <Section extraMargin={"8rem"}>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="font-w-extrabold">FrostBytes</h2>
            <p>
              FrostBytes is a web application for monitoring cold supply chain.
            </p>
            <img src={Banner} width="100%" className="pt-5" />
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Objective</h4>
            <p className="d-grey pt-5">
              FrostBytes is a web application for monitoring cold supply chain.
              This is a project we did in SAP Leonardo Industry Use Cases
              Hackathon. Luckily, we won the third prize!
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">What is Cold Chain?</h4>
            <p className="d-grey pt-5">
              Cold Chain is a temperature-controlled supply chain. We use ice
              cream as our use case to simulate the real-world scenario. Our
              goal is to monitor the temperature throughout the whole cold
              supply chain - moving through the truck loaded the milk, tank, ice
              cream factory, putting into a different patch, and to the end
              customer. We have a hardware device that embedded multiple
              sensors, such as GPS, temperature, etc. This device can pass the
              live data to our devices, such as Ipad or laptop.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">The Design</h4>
            <p className="d-grey pt-5">
              The UI is divided into two parts. The top section shows the
              general information and map while the bottom section shows the
              detail information about temperature and checkpoints.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={frostbyte1}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              After user enter the tracking code, some basic info will show on
              the top left.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={frostbyte2}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              Scroll down to see more info such as current temperature, ETA,
              etc.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={frostbyte3}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              If the temperature is in a danger zone, the system will make a
              phone call to the owner - “You Ice Cream is melting”
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">The App</h4>
            <p className="d-grey pt-5">
              Inputing the checking code the users will see the current location
              of the goods. Scrolling down the users can view more details, such
              as current temperature, temperature history and check points.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <video
              controls
              width="100%"
              autoPlay="true"
              loop="true"
              muted="true"
              className="d-block m-auto"
            >
              <source src={demo} type="video/mp4" />
            </video>
          </div>
        </div>
      </Section>
    </>
  )
}
